<template>
  <v-app>
    <NavBarMain />
    <v-main class="ma-4">
      <Softwares />
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Footer from '@/components/main/Footer';
import NavBarMain from '@/components/main/NavBarMain';
import Softwares from '@/components/main/Softwares.vue';

export default {
  data() {
    return {
      username: '',
    };
  },
  components: {
    Footer,
    NavBarMain,
    Softwares,
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
