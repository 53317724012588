<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-if="userPermissions[1]">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-4"
              link
              to="laundry/dashboard"
            >
              <v-card-subtitle> <h2>Pyykit</h2></v-card-subtitle>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-if="userPermissions[2]">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-4"
              link
              to="rental-clothes/show-rental-clothes-date"
            >
              <v-card-subtitle><h2>Vuokratekstiilit</h2></v-card-subtitle>
            </v-card>
          </template>
        </v-hover>
      </v-col>

      <v-col cols="12" v-if="userPermissions[3]">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-4"
              link
              to="clothes-to-rental"
            >
              <v-card-subtitle><h2>Tekstiilitilaukset</h2></v-card-subtitle>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-if="userPermissions[4]">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-4"
              link
              to="users"
            >
              <v-card-subtitle><h2>Käyttäjät</h2></v-card-subtitle>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        userPermissions: [],
      };
    },
    methods: {
      checkPermissions(value) {
        let permissions = value;
        if (permissions.length > 1) {
          let permissionsTable = permissions.split(',');

          for (let i = 0; i < permissionsTable.length; i++) {
            this.userPermissions[permissionsTable[i]] = true;
          }
        } else {
          if (value === '1') {
            this.$router.push('/laundry');
          } else if (value === '2') {
            this.$router.push('/rental-clothes');
          } else if (value === '3') {
            this.$router.push('/clothes-to-rental');
          } else {
            this.$router.push('/users');
          }
        }
      },
    },
    created() {
      this.checkPermissions(this.$store.state.user.userPermissions);
    },
  };
</script>
