<!-- Main view when user is logged in -->
<template>
  <v-app>
    <LaundryNavBar />

    <v-main class="ma-4">
      <router-view name="laundry"></router-view>
    </v-main>
    <LaundryFooter />
  </v-app>
</template>
<script>
import LaundryNavBar from '@/components/laundry/main/LaundryNavBar';
import LaundryFooter from '@/components/laundry/main/LaundryFooter';

export default {
  data() {
    return {
      username: '',
    };
  },
  components: {
    LaundryNavBar,
    LaundryFooter,
  },
  async created() {
    // if (!this.$store.getters.isLoggedIn) {
    //  this.$router.push('/login');
    // }
    this.username = this.$store.getters.getUser.username;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
