<template>
  <v-app>
    <v-container fill-height>
      <v-layout class="align-center justify-center">
        <v-flex xs12 sm8 md4>
          <v-form>
            <v-alert :value="true" dense outlined type="error" v-if="error">
              {{ msg }}
            </v-alert>
            <v-card class="elevation-5">
              <v-toolbar dark color="grey">
                <v-toolbar-title>
                  {{ $t('login.login_form') }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  prepend-icon="mdi-account"
                  :label="$t('login.email')"
                  type="text"
                  v-model="email"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-lock"
                  :label="$t('login.password')"
                  :type="show ? 'text' : 'password'"
                  v-model="current_password"
                  autocomplete="off"
                  @click:append="show = !show"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @keyup.enter="login"
                ></v-text-field>
              </v-card-text>
              <v-divider light></v-divider>
              <v-card-actions>
                <v-btn rounded color="grey" dark @click.prevent="login" block>
                  <v-icon left>mdi-pencil</v-icon>
                  {{ $t('login.login') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import AuthenticationService from '@/services/AuthenticationService.js';
export default {
  data() {
    return {
      email: '',
      show: false,
      current_password: '',
      message: '',
      error: false,
    };
  },
  created() {
    if (this.$store.getters.isAuthenticated) {
      // this.$router.replace('/').catch(() => {});
    }
  },

  methods: {
    async login() {
      try {
        const credentials = {
          userEmail: this.email,
          userPassword: this.current_password,
        };

        const response = await AuthenticationService.login(credentials);
        this.message = response.message;
        if (response.error) {
          this.error = response.error;
        }
        //talleta arvot storeen
        this.$store.dispatch('setToken', response.data.token);
        this.$store.dispatch('setUser', response.data.user);

        this.$router.replace({ name: 'main' });
      } catch (error) {
        this.message = error.response.data.message;
      }
    },
  },
};
</script>
