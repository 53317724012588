var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[(_vm.userPermissions[1])?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-4",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"laundry/dashboard"}},[_c('v-card-subtitle',[_c('h2',[_vm._v("Pyykit")])])],1)]}}],null,false,3870295050)})],1):_vm._e(),(_vm.userPermissions[2])?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-4",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"rental-clothes/show-rental-clothes-date"}},[_c('v-card-subtitle',[_c('h2',[_vm._v("Vuokratekstiilit")])])],1)]}}],null,false,2771842174)})],1):_vm._e(),(_vm.userPermissions[3])?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-4",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"clothes-to-rental"}},[_c('v-card-subtitle',[_c('h2',[_vm._v("Tekstiilitilaukset")])])],1)]}}],null,false,2724177307)})],1):_vm._e(),(_vm.userPermissions[4])?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-4",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"users"}},[_c('v-card-subtitle',[_c('h2',[_vm._v("Käyttäjät")])])],1)]}}],null,false,2108693012)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }