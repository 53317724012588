<template>
  <nav>
    <v-app-bar color="#373637" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left large>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="white"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"></v-icon></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text @click="logout">
        <v-icon right large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app color="#373637" width="300">
      <v-layout column>
        <v-flex class="mt-5" align-left>
          <p class="white--text subheading mt-1 text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <v-divider color="white"></v-divider>
        </v-flex>
      </v-layout>
      <v-list class="text-left">
        <!--Home link-->
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Etusivulle</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/laundry/add-laundry">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Lisää pyykit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group
          :value="false"
          prepend-icon="mdi-office-building"
          class="#66A7E6"
        >
          <template v-slot:activator>
            <v-list-item-title>Matot</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in carpets"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item-group>
          <v-list-item to="/laundry/show-reports">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä raportti</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <!--Home link end -->

        <!--User group -->

        <!-- <v-list-group
          :value="false"
          prepend-icon="mdi-account-circle"
          class="blue lighten-2"
        >
          <template v-slot:activator>
            <v-list-item-title>Käyttäjät</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            link
            :to="route"
            sub-group
            active-class="text--white"
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!--User group end -->
        <!--Targets group -->

        <v-list-group
          :value="false"
          prepend-icon="mdi-office-building"
          class="#66A7E6"
          v-if="user.userRoles === 'super-admin'"
        >
          <template v-slot:activator>
            <v-list-item-title>Hallinta</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in laundry"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--Targets group end -->

        <!--Targets group -->
        <!-- <v-list-group
          :value="false"
          prepend-icon="mdi-home-city"
          class="blue lighten-2 text--white"
        >
          <template v-slot:activator>
            <v-list-item-title>Vuoratekstiilit</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in rentalClothes"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!--Targets group end -->
        <!--Alarms group -->

        <!-- <v-list-group :value="false" prepend-icon="mdi-leak" class="blue">
          <template v-slot:activator>
            <v-list-item-title>Tekstiilivuokraus</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in ClothesToRental"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!-- <v-list-group
          :value="false"
          prepend-icon="mdi-leak"
          class="blue lighten-2"
        >
          <template v-slot:activator>
            <v-list-item-title>Tilitoimisto</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in AccountingFirm"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <!--Alarms group end -->
      </v-list>
      <!-- Drop-down -->

      <!-- Drop-down end -->
    </v-navigation-drawer>

    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';

export default {
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,

    users: [['Muuta salasana', 'mdi-account', '/laundry/change-password']],
    laundry: [
      ['Lisää ryhmä', '', '/laundry/add-group'],
      ['Näytä ryhmät', '', '/laundry/show-groups'],
      ['Lisää asiakas', '', '/laundry/add-customer'],
      ['Näytä asiakkaat', '', '/laundry/show-customers'],
      ['Lisää tuote', '', '/laundry/add-laundrytype'],
      ['Näytä tuotteet', '', '/laundry/show-laundrytypes'],
    ],
    carpets: [
      ['Lisää matto pesuun', '', '/laundry/add-carpet'],
      ['Näytä matot', '', '/laundry/show-carpets'],
    ],
    // AccountingFirm: [
    //   ['Lisää uusi organisaatio', '', 'add-organization'],
    //   ['Näytä organisaatiot', '', 'show-organizations'],
    // ],
  }),
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
</style>
